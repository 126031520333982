import React from "react"
import Layout from "../../../templates/layout"
import SEO from "../../../components/seo/seo"
import Hero from "../../../components/hero/hero"
import HaveQuestions from "../../../components/haveQuestions/haveQuestions"
import ExpandingCard from "../../../components/ExpandingCard/ExpandingCard"
import IconCard from "../../../components/iconCard/iconCard"
import BrandLogo from "../../../images/logos/jevtana-logo.svg"
import QuestionsIcon from "../../../images/icons/icon-questions-jevtana.svg"
import HeroIcon from "../../../images/icons/icon-forms-jevtana-hero.svg"


import EnrollmentForm from "../../../images/icons/elitek/enrollment-form.png";
import spanishEnrollmentForm from "../../../images/icons/elitek/spanish-enrollment-form.png";
import Officesupportbrochure from "../../../images/icons/hcp/office-support-brochure.png";
import PatientAssistanceProgramflashcard from "../../../images/icons/hcp/patient-assistance-program-flashcard.png";
import CopayProgramflashcard from "../../../images/icons/hcp/copay-program-flashcard.png";
import Alternatesourcesofcoverageflashcard from "../../../images/icons/hcp/alternate-sources-coverage-flashcard.png";
import Summarybenefitsflashcard from "../../../images/icons/hcp/summary-benefits-flashcard.png";
import CMSannotatedform from "../../../images/icons/hcp/cms-annotated-form.png";
import Priorauthorizationchecklist from "../../../images/icons/hcp/prior-authorization-checklist.png";
import Payerdenialflashcard from "../../../images/icons/hcp/payer-denial-flashcard.png";
import Letterofappealstemplate from "../../../images/icons/hcp/letter-appeals-template.png";
import Letterofmedicalnecessitytemplate from "../../../images/icons/hcp/letter-medical-necessity-template.png";
import Letterofmedicalexception from "../../../images/icons/hcp/letter-medical-exception-template.png";
import Billingandcodingguide from "../../../images/icons/hcp/billing-and-coding-guide.png";
import jevtanaBillingandcodingguide from "../../../images/icons/hcp/jevtana-billing-and-coding-guide.png";
import CareASSISTbillingandcodingguide from "../../../images/icons/hcp/careassist-billing-and-coding-guide.png";
import BackToTop from "../../../components/backToTop/BackToTop"
import PrescribingInfo from "../../../components/prescribingInfo/prescribingInfo";

const pageContent = () => (
    <Layout pageid="page-forms">
        <SEO
            title="Find CareASSIST resources and additional support options for eligible patients prescribed JEVTANA® (cabazitaxel) | HCP Site"
            keywords="CareASSIST, Sanofi, JEVTANA® (cabazitaxel), HCP Site, Resource Support"
            description="Download a brochure, a template, or explore online resources offering additional support for JEVTANA from CareASSIST. See full Prescribing Info, including Boxed WARNING"
        />

        <Hero
            brandLogo={BrandLogo}
            headline="Resource support"
            copy="We help connect patients to independent support organizations and resources throughout their treatment journey"
            heroImgClass="hero-icon"
            hasButton={false}
            hasLogo={true}
            hasIcon={true}
            heroIcon={HeroIcon}
            iconAlt="Resource Support Icon"
            brandAlt="Jevtana logo"
            flip={true}
        />

        <section className="content-section mobile-p-t-20">
            <blockquote className="jevtana mb-2 sm-max-width p-t-40">
                <ExpandingCard
                    cardClass="full jevtana"
                    mainContent={() => (
                        <div className="expanding-card-content header">
                            <h2>CareASSIST enrollment and program overview </h2>
                        </div>
                    )}
                    hiddenContent={() => (
                        <div className="expanding-card-content hidden">
                            <div className="grid-cols-sm-1 grid-cols-2 gap-30">
                                <IconCard
                                    icon={EnrollmentForm}
                                    alt="Enrollment form"
                                    cardClass="download-card jevtana"
                                    cardTitle="Enrollment form"
                                    cardText=""
                                    hasButton={false}
                                    isExternalLink={true}
                                    link="../../../pdfs/careassist-program-enrollment-form.pdf"
                                    linkClass="download-icon"
                                    linkTitle="Download the enrollment form"
                                    linkId="hcp-jevtana-resource-enroll-form"
                                    isBlur={true}
                                />
                                <IconCard
                                    icon={spanishEnrollmentForm}
                                    alt="Spanish Enrollment Form Icon"
                                    cardClass="download-card jevtana"
                                    cardTitle="Spanish enrollment form"
                                    cardText=""
                                    isExternalLink={true}
                                    link="../../../pdfs/careassist-spanish-enrollment-form.pdf"
                                    linkClass="download-icon"
                                    linkTitle="Download the Copay proof of expense form"
                                    linkId="hcp-jevtana-resource-spanish-enroll-form"
                                    isBlur={true}
                                />
                                <IconCard
                                    icon={Officesupportbrochure}
                                    alt="CareASSIST HCP Overview Brochure"
                                    cardClass="download-card jevtana"
                                    cardTitle="CareASSIST HCP overview brochure"
                                    cardText=""
                                    hasButton={false}
                                    isExternalLink={true}
                                    link="../../../pdfs/careassist-patient-support-program-overview-resource-hcp.pdf"
                                    linkClass="download-icon"
                                    linkTitle="Download the enrollment form"
                                    isBlur={true}
                                    linkId="hcp-jevtana-resource-overview-brochure"
                                />
                            </div>

                        </div>
                    )}
                />
                <ExpandingCard
                    cardClass="full jevtana"
                    mainContent={() => (
                        <div className="expanding-card-content">
                            <h2>Financial and reimbursement support </h2>
                        </div>
                    )}
                    hiddenContent={() => (
                        <div className="expanding-card-content hidden">
                            <h3>Financial tools </h3>
                            <div className="grid-cols-sm-1 grid-cols-2 gap-30">
                                <IconCard
                                    icon={CopayProgramflashcard}
                                    alt="Copay Program flashcard"
                                    cardClass="download-card jevtana"
                                    cardTitle="Copay Program flashcard"
                                    cardText=""
                                    hasButton={false}
                                    isExternalLink={true}
                                    link="../../../pdfs/careassist-copay-program-hcp-flashcard.pdf"
                                    linkClass="download-icon"
                                    linkTitle="Download the enrollment form"
                                    isBlur={true}
                                    linkId="hcp-jevtana-resource-copay-program-flashcard"
                                />
                                <IconCard
                                    icon={PatientAssistanceProgramflashcard}
                                    alt="Patient Assistance Program flashcard"
                                    cardClass="download-card jevtana"
                                    cardTitle="Patient Assistance Program flashcard"
                                    cardText=""
                                    hasButton={false}
                                    isExternalLink={true}
                                    link="../../../pdfs/careassist-pap-flashcard.pdf"
                                    linkClass="download-icon"
                                    linkTitle="Download the enrollment form"
                                    isBlur={true}
                                    linkId="hcp-jevtana-resource-pap-flashcard"
                                />

                            </div>
                            <hr className="jevtana only-mbl" />
                            <h3>Reimbursement tools</h3>
                            <div className="grid-cols-sm-1 grid-cols-2 gap-30">
                                <IconCard
                                    icon={Summarybenefitsflashcard}
                                    cardClass="download-card jevtana"
                                    cardTitle="Summary of benefits flashcard"
                                    alt="Summary of benefits flashcard"
                                    cardText=""
                                    hasButton={false}
                                    isExternalLink={true}
                                    link="../../../pdfs/careassist-summary-of-benefits-flashcard.pdf"
                                    linkClass="download-icon"
                                    linkTitle="Download the Summary of benefits flashcard"
                                    isBlur={true}
                                    linkId="hcp-jevtana-resource-benefits-flashcard"
                                />
                                <IconCard
                                    icon={CMSannotatedform}
                                    cardClass="download-card jevtana"
                                    cardTitle="Sample CMS annotated form flashcard"
                                    alt="Sample CMS annotated form flashcard"
                                    cardText=""
                                    hasButton={false}
                                    isExternalLink={true}
                                    link="../../../pdfs/careassist-sample-annotated-cms-form-flashcard.pdf"
                                    linkClass="download-icon"
                                    linkTitle="Download the Sample CMS annotated form flashcard"
                                    isBlur={true}
                                    linkId="hcp-jevtana-resource-annotated-form-flashcard"
                                />
                                <IconCard
                                    icon={Priorauthorizationchecklist}
                                    cardClass="download-card jevtana"
                                    cardTitle="Prior authorization checklist"
                                    alt="Prior authorization checklist"
                                    cardText=""
                                    hasButton={false}
                                    isExternalLink={true}
                                    link="../../../pdfs/careassist-prior-authorization-checklist.pdf"
                                    linkClass="download-icon"
                                    linkTitle="Download the Prior authorization checklist"
                                    isBlur={true}
                                    linkId="hcp-jevtana-resource-prior-authorization-checklist"
                                />
                                <IconCard
                                    icon={Payerdenialflashcard}
                                    cardClass="download-card jevtana"
                                    cardTitle="Payer denial flashcard"
                                    alt="Payer denial flashcard"
                                    cardText=""
                                    hasButton={false}
                                    isExternalLink={true}
                                    link="../../../pdfs/careassist-payer-denial-flashcard.pdf"
                                    linkClass="download-icon"
                                    linkTitle="Download the Payer denial flashcard"
                                    isBlur={true}
                                    linkId="hcp-jevtana-resource-payer-denial-flashcard"
                                />
                                <IconCard
                                    icon={jevtanaBillingandcodingguide}
                                    cardClass="download-card jevtana"
                                    cardTitle="JEVTANA billing and coding guide"
                                    alt="JEVTANA billing and coding guide"
                                    cardText=""
                                    hasButton={false}
                                    isExternalLink={true}
                                    link="../../../pdfs/jevtana-billing-coding-guide.pdf"
                                    linkClass="download-icon"
                                    linkTitle="Download the billing and coding guide"
                                    linkId="hcp-jevtana-resource-billing-coding-guide"
                                    isBlur={true}
                                />
                            </div>
                            <hr className="jevtana only-mbl" />
                            <h3>Sample letters</h3>
                            <div className="grid-cols-sm-1 grid-cols-2 gap-30">

                                <IconCard
                                    icon={Letterofappealstemplate}
                                    alt="Letter of appeals template"
                                    cardClass="download-card jevtana"
                                    cardTitle="Letter of appeals template"
                                    cardText=""
                                    hasButton={false}
                                    isExternalLink={true}
                                    link="../../../pdfs/careassist-sample-letter-of-appeals.docx"
                                    linkClass="download-icon"
                                    linkTitle="Download the Letter of appeals template"
                                    isBlur={true}
                                    linkId="hcp-jevtana-resource-letter-of-appeal-template"
                                />
                                <IconCard
                                    icon={Letterofmedicalnecessitytemplate}
                                    alt="Letter of medical necessity template"
                                    cardClass="download-card jevtana"
                                    cardTitle="Letter of medical necessity template"
                                    cardText=""
                                    hasButton={false}
                                    isExternalLink={true}
                                    link="../../../pdfs/careassist-sample-letter-of-medical-necessity.docx"
                                    linkClass="download-icon"
                                    linkTitle="Download the Letter of medical necessity template"
                                    isBlur={true}
                                    linkId="hcp-jevtana-resource-medical-necessity-template"
                                />
                                <IconCard
                                    icon={Letterofmedicalexception}
                                    alt="Letter of medical exception"
                                    cardClass="download-card jevtana"
                                    cardTitle="Letter of medical exception template"
                                    cardText=""
                                    hasButton={false}
                                    isExternalLink={true}
                                    link="../../../pdfs/careassist-sample-letter-of-medical-exception.docx"
                                    linkClass="download-icon"
                                    linkTitle="Download the Template of medical exception template"
                                    isBlur={true}
                                    linkId="hcp-jevtana-resource-medical-exception-template"
                                />
                            </div>
                        </div>
                    )}
                />
                <ExpandingCard
                    cardClass="full jevtana"
                    mainContent={() => (
                        <div className="expanding-card-content header">
                            <h2>Online resources*</h2>
                        </div>
                    )}
                    hiddenContent={() => (
                        <div className="expanding-card-content hidden">
                            <p className="mb-2">
                                Certain patients may require information or assistance beyond what
                                CareASSIST can offer. Below are links to external organizations
                                that may be able to help. You or your patients may also call &nbsp;
                                <a className="phone-number" href="tel:1-833-930-2273">1-833-WE+CARE</a> (<a className="phone-number" href="tel:1-833-930-2273">1-833-930-2273</a>) to learn more.
                            </p>
                            <div className="grid-cols-sm-1 grid-cols-2 gap-20">
                                <IconCard
                                    cardClass="external-link-card jevtana clickable-title"
                                    cardTitle="HealthCare.gov"
                                    alt="HealthCare.gov"
                                    cardText=""
                                    hasButton={false}
                                    isModalLink={true}
                                    isClickableTitle={true}
                                    modalLink="https://healthcare.gov"
                                    modalLinkId="hcp-jevtana-resource-healthcare-gov"
                                />
                                <IconCard
                                    cardClass="external-link-card jevtana clickable-title"
                                    cardTitle="Medicaid.gov"
                                    alt="Medicaid.gov"
                                    cardText=""
                                    hasButton={false}
                                    isModalLink={true}
                                    isClickableTitle={true}
                                    modalLink="https://medicaid.gov"
                                    modalLinkId="hcp-jevtana-resource-medicaid-gov"
                                />
                                <IconCard
                                    cardClass="external-link-card jevtana clickable-title"
                                    cardTitle="Medicare.gov"
                                    alt="Medicare.gov"
                                    cardText=""
                                    hasButton={false}
                                    isModalLink={true}
                                    isClickableTitle={true}
                                    modalLink="https://medicare.gov"
                                    modalLinkId="hcp-jevtana-resource-medicare-gov"
                                />
                                <IconCard
                                    cardClass="external-link-card jevtana clickable-title"
                                    cardTitle="Patient Advocate Foundation"
                                    alt="Patient Advocate Foundation"
                                    cardText=""
                                    hasButton={false}
                                    isModalLink={true}
                                    isClickableTitle={true}
                                    modalLink="https://www.patientadvocate.org/"
                                    modalLinkId="hcp-jevtana-resource-patientadvocate-org"
                                />
                                <IconCard
                                    cardClass="external-link-card jevtana clickable-title"
                                    cardTitle="Patient Advocate Network Foundation"
                                    alt="Patient Advocate Network Foundation"
                                    cardText=""
                                    hasButton={false}
                                    isModalLink={true}
                                    isClickableTitle={true}
                                    modalLink="https://www.panfoundation.org/"
                                    modalLinkId="hcp-jevtana-resource-panfoundation-org"
                                />
                                <IconCard
                                    cardClass="external-link-card jevtana clickable-title"
                                    cardTitle="American Cancer Society"
                                    alt="American Cancer Society"
                                    cardText=""
                                    hasButton={false}
                                    isModalLink={true}
                                    isClickableTitle={true}
                                    modalLink="https://cancer.org"
                                    modalLinkId="hcp-jevtana-resource-cancer-org"
                                />
                                <IconCard
                                    cardClass="external-link-card jevtana clickable-title"
                                    cardTitle="Cancer Support Community"
                                    alt="Cancer Support Community"
                                    cardText=""
                                    hasButton={false}
                                    isModalLink={true}
                                    isClickableTitle={true}
                                    modalLink="https://www.cancersupportcommunity.org/"
                                    modalLinkId="hcp-jevtana-resource-cancersupportcommunity-org"
                                />
                                <IconCard
                                    cardClass="external-link-card jevtana clickable-title"
                                    cardTitle="National Comprehensive Cancer Network<sup>®</sup>"
                                    alt="National Comprehensive Cancer Network<sup>®</sup>"
                                    cardText=""
                                    hasButton={false}
                                    isModalLink={true}
                                    isClickableTitle={true}
                                    modalLink="https://nccn.org"
                                    modalLinkId="hcp-jevtana-resource-nccn-org"
                                />
                                <IconCard
                                    cardClass="external-link-card jevtana clickable-title"
                                    cardTitle="CancerCare"
                                    alt="CancerCare"
                                    cardText=""
                                    isModalLink={true}
                                    isClickableTitle={true}
                                    modalLink="https://www.cancercare.org/"
                                    modalLinkId="hcp-jevtana-resource-cancercare-org"
                                />
                                <IconCard
                                    alt="FindHelp"
                                    cardClass="external-link-card jevtana clickable-title"
                                    cardTitle="FindHelp.org"
                                    cardText=""
                                    isModalLink={true}
                                    isClickableTitle={true}
                                    modalLink="https://www.FindHelp.org/"
                                    modalLinkId="hcp-jevtana-resource-findhelp-org"
                                />
                            </div>
                            <div className="important-info jevtana pt-1">
                                <p className="hang">*This listing is provided as a resource only and does not constitute an endorsement by Sanofi of any particular organization or its programming. Additional resources on this topic may be available and should be investigated. Sanofi does not review or control the content of non-Sanofi websites.</p>
                                <h4 className="m-t-0">INDICATION</h4>
                                <p>
                                    JEVTANA is a microtubule inhibitor indicated in combination with
                                    prednisone for treatment of patients with metastatic
                                    castration-resistant prostate cancer previously treated with a
                                    docetaxel-containing treatment regimen.
                                </p>

                                <h4>IMPORTANT SAFETY INFORMATION</h4>
                                <div className="warning mb-1">
                                    <h4>WARNING: NEUTROPENIA AND HYPERSENSITIVITY</h4>
                                    <ul>
                                    <li>
                                        <span style={{textDecoration: `underline`}}>Neutropenia</span>: Neutropenic deaths have been reported. Monitor for neutropenia with frequent blood cell counts. JEVTANA is contraindicated in patients with neutrophil counts of &le;1,500 cells/mm<sup>3</sup>. Primary prophylaxis with G-CSF is recommended in patients with high-risk clinical features. Consider primary prophylaxis with G-CSF in all patients receiving a dose of 25 mg/m<sup>2</sup>.
                                    </li>
                                    <li>
                                        <span style={{textDecoration: `underline`}}>Severe hypersensitivity</span>: Severe hypersensitivity reactions can occur and may include generalized rash/erythema, hypotension, and bronchospasm. Severe hypersensitivity reactions require immediate discontinuation of the JEVTANA infusion and administration of appropriate therapy. Patients should receive premedication. JEVTANA is contraindicated in patients who have a history of severe hypersensitivity reactions to cabazitaxel or to other drugs formulated with polysorbate 80.
                                    </li>
                                    </ul>
                                </div>
                                <h4>CONTRAINDICATIONS</h4>
                                <p>JEVTANA is contraindicated in patients with neutrophil counts of &le;1,500/mm<sup>3</sup>, patients with a history of severe hypersensitivity reactions to cabazitaxel or to other drugs formulated with polysorbate 80, and patients with severe hepatic impairment (total bilirubin &gt;3x upper limit of normal (ULN)).</p>
                                <h4>WARNINGS AND PRECAUTIONS</h4>
                                <p><strong>Bone Marrow Suppression (BMS):</strong> BMS manifested as neutropenia, anemia, thrombocytopenia, and/or pancytopenia may occur. Neutropenic deaths have been reported. Monitor blood counts frequently to determine if initiation of G-CSF and/or dosage modification is needed. Monitoring of complete blood counts is essential on a weekly basis during cycle 1 and before each treatment cycle thereafter so that the dose can be adjusted, if needed. Caution is recommended in patients with hemoglobin &lt; 10 g/dl.</p>
                                <p><strong>Increased Toxicities in Elderly Patients:</strong> Patients &ge;65 years of age were more likely to experience fatal outcomes not related to disease progression and certain adverse reactions, including neutropenia and febrile neutropenia. Monitor closely.</p>
                                <p><strong>Hypersensitivity Reactions:</strong> Severe hypersensitivity reactions can occur. Premedicate all patients with antihistamines, corticosteroids, and H<sub>2</sub> antagonists prior to JEVTANA. Observe patients closely, especially during the first and second infusions. Discontinue JEVTANA immediately if severe hypersensitivity occurs and treat as indicated.</p>
                                <p><strong>Gastrointestinal (GI) Adverse Reactions:</strong> Nausea, vomiting, and severe diarrhea may occur. Death related to diarrhea and electrolyte imbalance occurred in the randomized clinical trials and mortality related to diarrhea has been reported. Intensive measures may be required for severe diarrhea and electrolyte imbalance. Rehydrate and treat with antiemetics and antidiarrheals as needed. If experiencing grade &ge;3 diarrhea, dosage should be modified.</p>
                                <p>GI hemorrhage and perforation, ileus, enterocolitis, neutropenic enterocolitis, including fatal outcome, have been reported. Risk may be increased with neutropenia, age, steroid use, concomitant use of NSAIDs, antiplatelet therapy or anticoagulants, and prior history of pelvic radiotherapy, adhesions, ulceration and GI bleeding. Abdominal pain and tenderness, fever, persistent constipation, diarrhea, with or without neutropenia, may be early manifestations of serious GI toxicity and should be evaluated and treated promptly. JEVTANA treatment delay or discontinuation may be necessary.</p>
                                <p><strong>Renal Failure:</strong> Cases, including those with fatal outcomes, have been reported. Identify the cause and manage aggressively.</p>
                                <p><strong>Urinary Disorders including Cystitis:</strong> Cystitis, radiation cystitis, and hematuria, including that requiring hospitalization, has been reported with JEVTANA in patients who previously received pelvic radiation. Cystitis from radiation recall may occur late in treatment with JEVTANA. Monitor patients who previously received pelvic radiation for signs and symptoms of cystitis while on JEVTANA. Interrupt or discontinue JEVTANA in patients experiencing severe hemorrhagic cystitis. Medical and/or surgical supportive treatment may be required to treat severe hemorrhagic cystitis.</p>
                                <p><strong>Respiratory Disorders:</strong> Interstitial pneumonia/pneumonitis, interstitial lung disease and acute respiratory distress syndrome have been reported and may be associated with fatal outcomes. Patients with underlying lung disease may be at higher risk for these events. Acute respiratory distress syndrome may occur in the setting of infection. Interrupt JEVTANA if new or worsening pulmonary symptoms develop. Closely monitor, promptly investigate, and appropriately treat patients receiving JEVTANA. Consider discontinuation. The benefit of resuming JEVTANA treatment must be carefully evaluated.</p>
                                <p><strong>Use in Patients with Hepatic Impairment:</strong> JEVTANA dose should be reduced for patients with mild (total bilirubin &gt; 1 to &le; 1.5 x ULN or AST &gt; 1.5 x ULN) and moderate (total bilirubin &gt; 1.5 to &le; 3.0 x ULN and any AST) hepatic impairment, based on tolerability data in these patients. Administer JEVTANA 20 mg/m<sup>2</sup> for mild hepatic impairment. Administer JEVTANA 15 mg/m<sup>2</sup> for moderate hepatic impairment. Monitor closely.</p>
                                <p><strong>Embryo-Fetal Toxicity:</strong> JEVTANA can cause fetal harm and loss of pregnancy. Advise males with female partners of reproductive potential to use effective contraception during treatment and for 4 months after the last dose of JEVTANA.</p>
                                <h4>ADVERSE REACTIONS (ARs)</h4>
                                <p>The most common all grades adverse reactions and laboratory abnormalities (&ge;10%) with JEVTANA 20 mg/m<sup>2</sup> or 25 mg/m<sup>2</sup> are neutropenia, anemia, diarrhea, nausea, fatigue, asthenia, vomiting, hematuria, constipation, decreased appetite, back pain, and abdominal pain.</p>
                                <h4>DRUG INTERACTIONS</h4>
                                <p>Avoid coadministration of JEVTANA with strong CYP3A inhibitors. If patients require coadministration of a strong CYP3A inhibitor, consider a 25% JEVTANA dose reduction.</p>
                                <h4>USE IN SPECIFIC POPULATIONS</h4>
                                <ul>
                                    <li><strong>Pregnancy:</strong> The safety and efficacy of JEVTANA have not been established in females. There are no human data on the use of JEVTANA in pregnant women to inform the drug-associated risk.</li>
                                    <li><strong>Lactation:</strong> The safety and efficacy of JEVTANA have not been established in females. There is no information available on the presence of JEVTANA in human milk, the effects of the drug on the breastfed infant, or the effects of the drug on milk production.</li>
                                    <li><strong>Females and Males of Reproductive Potential:</strong> Advise male patients with female partners of reproductive potential to use effective contraception during treatment and for 4 months after the last dose of JEVTANA.</li>
                                </ul>
                                <p><strong>Please see full <a target="_blank" href="https://products.sanofi.us/jevtana/jevtana.html">Prescribing Information</a>, including Boxed WARNING.</strong></p>



                            </div>
                        </div>
                    )}
                />
            </blockquote>
            <div className="mobile-m-t-40">
                {/* <PrescribingInfo linkId="hcp-jevtana-resource-prescribing-information" link="https://products.sanofi.us/jevtana/jevtana.html" /> */}
                <HaveQuestions icon={QuestionsIcon} />
            </div>
        </section>
        <div className="back-to-top-container">
            <BackToTop />
        </div>
    </Layout>
);

export default pageContent